.tab_scroller_container {
	margin-top: 59px;
}

.iconContainer{
    position: fixed;
    left: -5px; 
    display: inline-block;
}

.linksContainer {
	position: absolute;
	left: 19px;
	top: 8px;
	background-color: #f4f4f4;
	transform-origin: left;
	transform: translateX(-8px);
	opacity: 0;
	visibility: hidden;
	transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out,
		visibility 0.3s ease-in-out;
}

.linksContainerOpen {
	transform: translateX(0);
	opacity: 1;
	visibility: visible;
	overflow-x: hidden;
    width: 98%;
    top: 58px;
    left: 38px;
}

.linksWrapper {
	display: flex;
	align-items: center;
}

.linksWrapper::-webkit-scrollbar {
	display: none; /* Chrome, Safari, Opera */
}

.link {
	display: flex;
	align-items: center;
	min-width: max-content;
	font-size: 14px;
	color: #4b5563;
	text-decoration: none;
	transition: background-color 0.2s;
    padding: 8px 15px;
}

.list_empty {
	display: flex;
	align-items: center;
	min-width: max-content;
	font-size: 14px;
	color: #4b5563;
	text-decoration: none;
	transition: background-color 0.2s;
    padding: 8px 15px;
}

.link:hover {
    background: #fff;
    font-weight: 500;
    border-bottom: 3px solid #ececec;
    color: #188eff;
}

